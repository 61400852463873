$font_family: "Montserrat", sans-serif
$font-path: "Montserrat" !default
$font-name: "Montserrat" !default

// $font_family: 'CenturyGothic', sans-serif
// $font-path: "CenturyGothic" !default
// $font-name: "CenturyGothic" !default
$font-folder: "~/public/assets/fonts"

$bg_color: #fff1f1
$white: #ffffff
$color_1: #333333 // grigione
$color_2: #ffffff // bianco
$color_3: #94d3dc // celestino
$color_4: #f5f5f3 // grigetto
$color_5: #205344 // verdone
$color_6: #f16b38 // aranciotto

$smallest: 500px
$smartphone: 900px
$desktop: 1024px
$desktop-max-width: 1280px
$page-size: 110rem //75

%title_section
  font-size: 3.5rem
  font-weight: 500

%title_article
  font-size: 2.5rem
  font-weight: 500

%subtitle
  font-size: 1.8rem
  font-weight: 500
  @media (min-width: $desktop-max-width)
    font-size: 2.5rem
    font-weight: 500

%text
  font-size: 1.7rem
  font-weight: 400

%small_text
  font-size: 1rem
  font-weight: 400

%dropshadow
  filter: drop-shadow(0rem 0.2rem 0.2rem rgba(0, 0, 0, .8))
%dropshadow-strong
  filter: drop-shadow(0rem 1rem 1rem rgba(0,0,0,0.7))

html
  font-size: 62.5%
  height: 100%

*
  margin: 0
  padding: 0
  box-sizing: border-box

*:focus
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4)
a, button
  text-decoration: none
  outline: 0
  &:hover
    opacity: .95
    cursor: pointer

a
  color: inherit
ul, ol
  list-style: none
  padding: 0

u
  display: inline
pre
  margin: 4rem 0
// body
//   font: 1.6rem $font_family
//   color: $color_1
//   text-align: center
//   background-color: $bg-color
//   font-weight: 300

img
  max-width: 100%
select, input, button
  border: 0
  outline: 0
  background: transparent

select, input
  width: 100%
  height: 4rem
  font-size: 2rem
  border: .1rem solid #eee
  outline: 0
  padding: .5rem
  background: transparent
  margin: 1rem 0
input.date, input.time
  width: 48%
  margin: 1rem 1%

h1, h2
  font-weight: 700
// h1, h2, h3, p, .b_para, .t_1, .t_2, .t_3, .t_4, time, li
//   letter-spacing: 0.01px
h1
  @extend %title_article
h2
  @extend %subtitle
article
  max-width: 100%

figure
  text-align: left
  border: 0
  border-radius: 0
  background: transparent
  padding: 0
  margin: 0
  display: block

svg:not(:root)
  overflow: hidden

.b_frame
  width: 100%
  display: inline-block
  float: none
  margin: 0 auto
  max-height: 60%
  padding-bottom: 56.25% /* 16:9 */
  position: relative
  height: auto
  iframe, video
    width: 100%
    height: 100%
    display: block
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: 0
    padding: 0
    border: 0
#overlay
  .c_frame
    margin: 0 auto
    width: 100vh
    height: 80vh

blockquote
  // color: $color_1
  font-weight: 300
  font-size: 2.6rem
  line-height: 1.1
  text-align: center
  margin: 2rem auto
  padding: 1rem
  quotes: "\201C""\201D""\2018""\2019"
  &:before
    content: open-quote
  &:after
    content: close-quote

.offscreen // SEO trick
  clip-path: inset(100%)
  clip: rect(1px, 1px, 1px, 1px)
  height: 1px
  overflow: hidden
  position: absolute
  white-space: nowrap
  width: 1px

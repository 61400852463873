.logo
  display: block
  margin: 0 auto
  width: fit-content
.hidden
  display: none
a
  cursor: pointer
.sided
  width: 100%
  float: left
  @media (min-width: $smartphone)
    width: 60%
  + *
    width: 100%
    float: right
    @media (min-width: $smartphone)
      width: 35%
      min-width: 32rem

.half
  width: 100%
  @media (min-width: $smartphone)
    width: 50%
    float: left
  + *
    width: 100%
    @media (min-width: $smartphone)
      width: 50%
      float: right

.sr-only
  position: absolute
  left: -10000px
  top: auto
  width: 1px
  height: 1px
  overflow: hidden

.full_row
  display: flex
  flex: 0 1 100%
  justify-content: center

@media (min-width: $smartphone)
  .left
    float: left
    margin-right: 2rem
  .right
    float: right
    margin-left: 2rem
header,section > article
  &:hover
    opacity: .8
body
  color: #000
  font-family: 'Montserrat'
  background-color: $bg_color
  &.hp
    // background-color: $color_3
      // background-color: #e5bcc9
    .links_social a
      // color: #FFF
    #page
      header
        margin-bottom: 0
      .hero
        // background-image: url(/assets/img/sayhi.jpg)
        // background-image: url(/assets/img/market_20191215_v.jpg)
        background-repeat: no-repeat
        background-position: center
        background-size: contain
        // min-height: 50vh

        img
          max-height: 80vh

        .store
          display: flex
          max-width: unset
          align-content: center
          justify-content: center
          flex-flow: column
          &:hover
            // opacity: .5
            filter: drop-shadow(0px 0px 15px rgba(145, 14, 92, 0.4))
          h3
            font-size: 1.8rem
            margin: auto
          img
            margin: auto
  &.events
    // background-color: #fff
    &.xmas
      // background-color: #102a22
      color: #fff
      p
        line-height: 2.2rem
      a
        color: #fff
      .links_social>li, nav ul li
        a
          color: #fff
          &.selected a
            border-bottom: .1rem solid #fff
  // &.contacts
  //   background-color: $color_4
  &.xmas
    // background-color: #112b22
    color: #fff
    p
      line-height: 2.2rem
    a
      color: #fff
    .links_social>li,nav ul li
      a
        color: #fff
        &.selected a
          border-bottom: .1rem solid #fff
  &.market
    // background-color: #99c9d5
  &.xr
    // background-color: #bf8522
    @media (min-width: $smartphone)
      img
        max-width: 100rem
  &.pink
    // background-color: #f6baca
  &.green
    // background-color: #8f9e8a
  section
    display: inline-block
    width: 100%
    max-width: 100%
    // text-align: justify
    > h2
      text-align: center
      margin: 0 auto 4rem
      @extend %title_section
  .next
    margin-top: 18rem
    padding: 2rem 0 3rem
    background: rgba(255,255,255,0.1)
    &.cards
      display: flex
      justify-content: center
      flex-wrap: wrap
      margin: auto
      article
        display: flex
        flex: 1 1 100%
        max-width: 30rem
        margin: 2rem
        img
          max-width: 100%
a
  // text-decoration: none
  color: $color_1
  font-weight: 500
.orange
  color: $color_6

.t_2
  font-weight: 500
  font-size: 4rem
  line-height: 1.5
  text-transform: uppercase
  padding: 0
  color: $color_1
.t_3
  font-weight: 500
  font-size: 2rem
  line-height: 1.5
  text-transform: uppercase
  padding: 0
  color: $color_1
.t_4
  font-weight: 500
  font-size: 3rem
  line-height: 1.5
  text-transform: uppercase
  padding: 0
  color: $color_1

#spinner
  width: 100%
  height: 100%
  opacity: .3
  img
    width: auto
    height: 75vh
    display: flex
    margin: auto
    animation: rotate 9.5s linear infinite

@keyframes rotate
  to
    transform: rotate(360deg)

sup
  font-size: 1.2rem
#page
  width: 100%
  display: flex
  flex-flow: column
  min-height: 100vh
  background-image: url('/assets/img/sayhi.svg')
  background-repeat: no-repeat
  background-size: cover
  .c_sizer
    width: 100%
    max-width: $page-size
    margin: 0 auto
    padding: 0 1rem
    .t_2, .t_3
      margin: 0 auto 2rem
      text-align: center
  > header
    display: flex
    flex-flow: column nowrap
    justify-content: center
    padding: 1rem 0
    height: auto
    // margin: 0 auto 2rem
    @media (min-width: $desktop-max-width)
      flex-flow: row nowrap
      padding: 1rem 0
    h2
      font-size: 2rem
      margin: 0 auto
      padding: 1rem 0
      width: 100%
      text-align: center
    img
      max-height: 10rem
      min-width: 14rem
      margin: auto
      text-align: center
      @media (min-width: $desktop-max-width)
        margin: auto auto auto 0
.hero
  color: #fff
  font-size: 10vw
  display: flex
  flex-flow: column
  justify-content: center
  width: auto
  height: auto
  margin: 2rem auto
  position: relative
  z-index: 1
  text-align: center
  font-family: 'Montserrat', cursive
  font-weight: 700

nav
  display: flex
  flex-flow: column
  flex-wrap: nowrap
  width: 100%
  padding: 2rem 0
  @media (min-width: $desktop-max-width)
    flex-flow: row
    width: auto
  ul
    li
      .icon-instagram::before
        font-size: 3rem
        line-height: 0
    &.links_social
      padding: 2rem 0
      order: -1
      @media (min-width: $desktop-max-width)
        order: 0
        padding: 0
    li
      margin: .5rem
      white-space: nowrap
      @media (min-width: $desktop-max-width)
        margin: 2rem
      a
        color: #000
        line-height: 2rem
        font-weight: 500
        font-size: 1.3rem
        text-transform: uppercase
        @media (min-width: calc($smartphone / 2))
          font-size: 1.6rem
        &.selected span
          border-bottom: .1rem solid #000

// a:visited
  // text-decoration: none
  // color: #fcfcfc

img
  width: auto
  max-height: 100vh
  margin: 2rem auto
  max-width: 100%

@media screen and (min-width: $smartphone)
  body.hp #page .hero
    flex-flow: row wrap
    // height: calc(100vh - 17rem)
    &.mini
      height: auto
      & + .next
        margin-top: 2rem
    // .store
    //   max-width: 50%
    //   margin: auto 0
    //   h3
    //     margin: auto auto 0
    //   img
    //     margin: 0 auto auto

  img
    padding: 0
    margin: auto 0
    max-width: 50rem
    max-height: 100%

nav ul
    display: flex
    list-style: none
    margin: auto
    justify-content: center
    flex-wrap: wrap
    @media (min-width: 600px)
      flex-wrap: nowrap
section.info
  p
    font-size: 2rem
    line-height: 1.3
  .c_sizer > *
    display: flex
    justify-content: center
    max-width: $page-size
    margin: 2rem auto
    min-height: 10vh

.map
  img
    display: flex
    margin: 3rem auto

footer, .gang_starr
  display: inline-flex
  width: 100%
  margin: 0 auto
  padding: 0 2rem
footer
  margin: auto auto .5rem
.hp
  .gang_starr
    display: none
  .next
    .gang_starr
      display: flex
.gang_starr
  justify-content: center
  margin: 3rem auto
  > *
    font-size: 2rem
    font-weight: bolder
    color: #666
    margin: 2rem
#colophon
  font-size: 1.2rem
  padding: 2rem 0
  display: flex
  flex-flow: row
  width: 100%
  flex-wrap: wrap
  align-items: center
  justify-content: center
  @media (max-width: $smartphone)
    flex-flow: column

.links_social
  padding: 2rem 0
  margin: 0 0 0 auto
  justify-content: flex-end
  display: flex
  width: auto
  color: $color_6
  @media (min-width: $smallest)
    padding: 0
    margin: -2.5rem 0 0 auto
  > li
    display: flex
    justify-content: center
    margin: auto 1rem
    a
      font-size: 1.8rem
      color: $color_1

.address
  text-align: center
  margin: auto
  & + .address.newsletter
    max-width: 60rem
    margin-top: 6rem
  img
    // max-height: 50rem
    // float: left
  .half
    padding-top: 4rem

ul.location
  width: 30rem
  float: none
  margin: 0 auto
  text-align: center
  @media (min-width: $smartphone)
    float: right
  li
    margin: 0 0 3rem
    span
      display: block
      font-size: 1.6rem
      margin: 0 0 1rem
      span
        display: inline
        padding-left: .5rem
      &.label
        font-weight: 500
        padding-bottom: 1rem
        text-transform: uppercase
      &:before
        padding-right: 1rem
section
  article
    // max-width: $desktop
    margin: 0 auto 2rem

article
  flex-flow: column
  text-align: left
  > *
    text-align: left
    width: 100%
  header
    // float: left
    width: 100%
    // margin-bottom: 4rem
    // @media (min-width: $smartphone)
    //   width: calc(100% - 15vw - 4rem)
    span
      font-size: 1.4rem
  figure, video, picture
    display: flex
    flex-direction: column
    margin: 2rem auto
    position: relative
    max-height: 75vh
    // aspect-ratio: calc(16/9)
    // @extend %dropshadow-strong
    // @media (min-width: $smartphone)
    //   float: right
    //   margin: 0 auto 15vh
    figcaption
      font-style: italic
      text-align: right
    img
      margin: auto
      position: absolute
      top: 0
      // top: 50%
      // left: 50%
      // transform: translate(-50%,-50%)
      min-width: auto
      min-height: 100%
      object-fit: contain
    &.round,&.corner
      width: 15rem
      height: 15rem
      border-radius: 50%
      overflow: hidden
      @media (min-width: $smartphone)
        margin: 0 auto 15rem
    &.full
      width: 100%
      height: auto
      position: relative
      margin: 0 0 2rem
      img
        position: relative
        transform: none
        // top: 0
        // left: 0
        @media (min-width: $smartphone)
          margin-right: 0
    &.corner
      overflow: unset
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.45))
      max-height: 50vh
      margin-bottom: 0
      img
        border-radius: .8rem
        min-height: 0
        max-height: 100%
  [class^="icon-"]:before,[class*=" icon-"]:before
        padding-right: 1rem
        font-size: 1.7rem
        line-height: 0.7
  .details
    display: flex
    flex-direction: column
    justify-content: space-between
    figure
      margin-top: 1rem
    @media (min-width: $smartphone)
      flex-direction: row
      > *
        max-width: 50%
  .b_content
    display: flex
    flex-direction: column
    font-size: 1.4rem
    padding-top: 2rem
    .socials
      display: flex
      flex-direction: column
      a
        display: flex
        line-height: 1
        margin-block: .5rem
    .icon-mail-b:before
      padding-left: 1rem
      padding-right: .5rem
  .time
    display: inline-block
    margin: 0 0 1rem
    text-transform: capitalize
    span
      margin-left: 2rem
  h2
    @extend %title_article
  h3
    @extend %subtitle
  h4
    margin-bottom: 1rem
    text-transform: uppercase
    strong
      display: inline
      font-weight: 500
  p
    padding: 0 0 2rem
    line-height: 2rem
    @media screen and ( min-width: 640px )
      // text-align: justify
  // br
    // margin-bottom: 2rem
  cite, strong
    display: block
    padding-top: 2rem
  .subscribe
    margin-block: 1.5rem
    display: flex
  .author, .price, .seats
    display: inline-block
    width: 100%
    padding: 0
    line-height: 3.4rem
  .author
    em
      padding-left: .2rem
  .price
    &:before
      vertical-align: text-bottom
      font-size: 3rem
  .icon-link
    .icon-facebook:before
      color: #4267b2
  .info
    display: flex
    flex-flow: column
    padding: 2rem 0
    .contacts
      display: flex
      justify-content: space-between
      padding: 2rem 0
      .icon-poi
        flex: 0
        display: flex
    .label
      display: flex
      width: 100%
      padding: 1rem 0
      font-weight: 500
    .socials
      margin: auto 0 auto auto
      a
        font-weight: 400
        font-size: 1.2rem
        margin-left: 1rem
      [class^="icon-"]:before, [class*=" icon-"]:before
        text-align: center
        padding-right: .5rem
        vertical-align: text-top
.meta
  display: flex
  margin: 2rem 0
  span
    display: flex
    padding: .5rem 1rem
    margin: 1rem
    border: .1rem solid #000000
    border-radius: .6rem
    &:nth-of-type(1)
      margin-left: 0

.who
  .c_sizer > *
    max-width: $page-size
  .b_profile
    display: flex
    flex-flow: column
    justify-content: center
    margin: auto auto 2rem
    @media (min-width: $smartphone)
      flex-flow: row
    > *
      display: flex
      flex-flow: column
      justify-content: center
      margin: auto
      @media (min-width: $smartphone)
        width: 50%
        &:nth-child(1)
          margin-right: 1rem
        &:nth-child(2)
          margin-left: 1rem
    figure
      width: 20rem
      height: 20rem
      margin: 2rem auto
      // @extend %dropshadow-strong
    header
      text-align: center
      @media (min-width: $smartphone)
        text-align: left
  h3
    @extend %text
    font-style: italic
    margin: 1rem 0
  .b_content
    display: inline-block

.workshops,.events
  & + .workshops,& + .events
    padding-top: 5rem
  &.list
    article
      &:hover,&:focus
        background: rgba(255, 255, 255, 0.85)
        opacity: 1
        a
          span
            font-weight: 500
            &.time::after
              transform: rotate(0)
      >.full
        &:before
          top: 0
          padding: 1rem
          content: 'More info coming soon'
          bottom: 0
          font-style: italic
          color: #2aabb0
          right: -1.3rem
          font-size: 1.2rem
          border-radius: 1rem
          @media (min-width: 600px)
            position: absolute
            transform: rotate(90deg)
            background: #fff
        &:after
          content: ''
          position: absolute
          top: 0
          left: 0
          z-index: 1
          bottom: 0
          margin: auto
          right: 0
          background: #eaeaea
          width: 100%
          height: 100%
          opacity: 0.4
  .hardware
    margin: 0 0 2rem
    list-style: disc
    padding-left: 2rem
    li:only-child
      list-style: none
      padding: 0
      margin-left: -2rem
  .t_2
    display: none
  .done
    opacity: .8
    background: transparent
    transition: all .4s
    a
      span
        font-weight: 300
    &:hover,&:focus
      background: rgba(255, 255, 255, 0.55)
      opacity: 1
      a
        span
          font-weight: 500
          &.time::after
            transform: rotate(0)

  .text
    font-size: 1.4rem
  .past
    padding: 2rem 0
    .t_4
      text-align: center
    article
      padding: 1rem
      margin: 0
      border-top: .1rem solid #666

      // padding: 1rem 1rem 3rem 1rem
      // margin: -1.5rem 0 0 0
      // box-shadow: 0 0 2.2rem -1rem #666
      // border-radius: 2rem 2rem 0rem 0rem
      // background: #fff
      // opacity: 1

      &:first-child
        border-top: .1rem solid transparent
      a
        display: flex
        justify-content: center
        .time,.title
          display: flex
          flex-flow: column
          text-align: left
        .time
          flex: 1 0 26rem
          margin: auto 0
          font-size: 1.3rem
          line-height: 1.5
          position: relative
          > *
            transition: all .4s
          &:after
            position: absolute
            content: ''
            top: 0
            bottom: 0
            left: 0
            right: -22rem
            margin: auto
            width: .1rem
            background: #666
            transform: rotate(-20deg)
            transition: all .4s
          .weekday
            text-transform: uppercase
          // .date
          //   font-weight: 700
        .title
          width: calc( 100% - 26rem )
          margin: auto auto auto 0
          line-height: 1.2
          .author
            width: 100%
            flex: 1 1 auto
            font-size: 1.3rem
            line-height: 1.5
            margin: auto
            padding: 0
            transition: all .4s
      &:not(:hover)
        .title .author
          margin: auto
          transition: all .4s

        .date
          transform: translateX(2rem)
          transition: all .4s
        .year
          transform: translateX(4rem)
          transition: all .4s

.tags
  margin-block: auto 2rem
  .tag:not(:empty)
    background-color: #ddd
    color: #000
    margin-right: 1rem
    font-size: 1.2rem
    text-transform: uppercase
    padding: .5rem 1rem
    border-radius: .4rem
    font-weight: 500
.timetable
  .time
    font-weight: 500
    margin-top: 2rem
  h4
    margin: 0
    font-weight: 400
    span
      color: #fff
      background-color: #ec8055
      display: inline
      padding: 0 .5rem
      border-radius: .2rem
    i
      padding: 0 .5rem
  .dl
    margin: 2rem 0

.reflect
  display: inline-block
  animation: reflect 1.4s linear infinite
.bigger
  display: inline-block
  animation: bigger 1.4s linear infinite
  overflow: hidden
.toup
  display: inline-block
  height: 2rem
  overflow: hidden
  em
    display: block
    animation: toup 1.4s linear infinite
    transform: translateY(150%)
.rotator
  display: inline-block
  animation: rotator 1.4s linear infinite
.blink
  display: inline-block
  animation: blinker 1.4s linear infinite

@keyframes reflect
  50%
    transform: scaleX(-1)
  100%
    transform: scaleX(1)

@keyframes toup
  80%
    transform: translateY(0)
  100%
    transform: translateY(0)

@keyframes bigger
  0%
    font-size: 0
  50%
    font-size: .8rem
  100%
    font-size: 2rem

@keyframes blinker
  100%
    opacity: 0.6

@keyframes rotator
  0%
    transform: rotate(0deg)
  50%
    transform: rotate(25deg)
  100%
    transform: rotate(-25deg)

.workshops,.events
  .past article a
    .tags .tag
      margin: 0.5rem
      white-space: nowrap
    @media screen and ( max-width: 640px )
      flex-flow: column
      .time
        flex-flow: row
        flex: 1 0 2rem
      .time, .title
        text-align: left
        justify-content: left
        width: calc(100% - 6rem)
        margin-bottom: 2rem
        > *
          margin-right: .5rem
        &:after
          display: none
      .title .author
        margin: auto
.artists .full_row
  margin-bottom: 3rem
  flex-wrap: wrap
  em
    padding-left: .5rem
#page .gallery
  .c_sizer
    max-width: 45rem * 2
    text-align: center
ul#gallery
  display: flex
  flex-flow: row wrap
  justify-content: space-between
  margin: 1rem auto
  li
    display: flex
    flex: 1 1 50%
    width: auto
    height: auto
    margin: auto
    padding: 1rem
    position: relative
    @media screen and ( max-width: 640px )
      flex: 1 1 100%
      height: auto
    &::after
      content: ""
      position: absolute
      bottom: 1rem
      left: 0
      right: 0
      margin: auto
      background-image: url(/assets/img/sprnv_logo_w.png)
      width: 14rem
      height: 7rem
      background-repeat: no-repeat
      background-size: contain
      opacity: 0.6
    &:not(:hover)
      opacity: 0.5
    img
      width: auto
      max-height: 40rem
      margin: auto
      border-radius: 0.5rem
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,.6)
      position: relative
      @media screen and ( min-width: 640px )
        // width: 100%

.search-bar
  max-width: 50rem
  width: 100%
  margin: 4rem auto 1rem

$btnColor: #82cbcf
:root
  --base-font-family: "Montserrat", sans-serif
  --base-transition: 600ms cubic-bezier(0.22, 1, 0.19, 1)
  --base-vertical-rhythm: 1.5rem

  --button-bg-color: mediumseagreen
  --button-border-radius: 0.15rem
  --button-focus-bg-color: seagreen
  --button-font-size: 1rem
  --button-text-color: white

  --input-bg-color: white
  --input-border-color: #eaeaea
  --input-border-focus-color: #d8d8d8
  --input-border-radius: 0.15rem
  --input-error-color: red
  --input-padding: 1.3rem
  --input-placeholder-color: #666
  --input-text-color: black
  --input-underline-color: dodgerblue
  --form-control-color: mediumseagreen
  --form-control-disabled: #959495
*
  box-sizing: border-box

// body
//   display: flex
//   flex-direction: column
//   justify-content: center
//   text-align: left
//   font-family: "Lato", sans-serif
//   min-height: 100vh
//   padding: 1rem
//   background-color: #fafafa
.indicates-required
  text-align: left
  font-size: 1.2rem
  font-style: italic
  margin: 0 0 2rem 0
.content__gdpr
  text-align: left
// Form groups
fieldset
  outline: 0
  border: 0
  margin-block: .5rem
.form-group
  position: relative
  display: flex
  flex-direction: column
  width: 100%
  border-radius: var(--input-border-radius)

  & + *:not(.indicates-required)
    margin-top: var(--base-vertical-rhythm)

  &.required
    label::after
      content: '*'
      padding: 0 .25rem

  // Labels
  label:not(.checkbox)
    position: absolute
    top: var(--input-padding)
    left: calc(var(--input-padding) + 1px)
    margin-bottom: calc(var(--input-padding) * -1.5)
    font-size: 1rem
    letter-spacing: 0.0025rem
    text-transform: uppercase
    z-index: 1
    pointer-events: none
    opacity: 0
    transform: translateY(calc(var(--input-padding) / 4))
    transition: opacity var(--base-transition), transform var(--base-transition)

    &:focus-within &
      opacity: 1
      transform: translateY(0)

    .error
      color: var(--input-error-color)
      opacity: 0
      transition: opacity var(--base-transition)

      &:before
        content: " - "

  // Inputs
  input[type="text"],
  input[type="email"],
  input[type="number"],
  select
    -webkit-appearance: none
    padding: calc(var(--input-padding) * 1.5) var(--input-padding)
    font-family: var(--base-font-family)
    font-size: 1rem
    width: 100%
    color: var(--input-text-color)
    background-color: var(--input-bg-color)
    outline: none
    border: 1px solid var(--input-border-color)
    border-radius: var(--input-border-radius)
    box-shadow: rgba(black, 0.05) 0 0.5rem 1rem -0.25rem
    transition: border-color var(--base-transition), box-shadow var(--base-transition), transition var(--base-transition)

    &::placeholder
      color: var(--input-placeholder-color)
      transition: color var(--base-transition), transform var(--base-transition)
      transition-delay: 200ms

    &:focus
      border-color: var(--input-border-focus-color)
      box-shadow: none
      + label
        opacity: 1
        transform: translateY(0)

    &:focus::placeholder
      color: transparent
      transform: translateX(calc(var(--input-padding) / 2))
      transition-duration: 0ms
      transition-delay: 0ms

    &:focus,&:not(:placeholder-shown)
      padding: calc(var(--input-padding) * 2) var(--input-padding) var(--input-padding)

    &:not(:placeholder-shown)
      &:invalid
        color: var(--input-error-color)
        border-color: var(--input-error-color)

        + label .error
          opacity: 1

      + label
        opacity: 1
        transform: translateY(0)

    &:-webkit-autofill + label
      opacity: 1

  select
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat
    background-position: calc(100% - 0.75rem) center !important
    -moz-appearance: none !important
    -webkit-appearance: none !important
    appearance: none !important
    padding-right: 2rem !important
  // Buttons
  // Reset default button styles
button.button
  border: none
  background-color: transparent
  font-family: inherit
  padding: 0
  cursor: pointer

  @media screen and (-ms-high-contrast: active)
    border: 2px solid currentcolor

button.button
  // Display
  display: inline-flex
  align-items: center
  justify-content: center
  align-self: start // Optional - see "Gotchas"

  // Visual
  background-color: $btnColor
  color: #000
  border-radius: 8px
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.18)

  // Size
  padding: 0.25em 0.75em
  min-width: 10ch
  min-height: 44px

  // Text
  text-align: center
  line-height: 1.1

  transition: 220ms all ease-in-out

  &:hover,
  &:active
    background-color: scale-color($btnColor, $lightness: -20%)

  &:focus
    outline-style: solid
    outline-color: transparent
    box-shadow: 0 0 0 4px scale-color($btnColor, $lightness: -40%)

  &--small
    font-size: 1.15rem

  &--block
    width: 100%

label.checkbox
  display: grid
  grid-template-columns: 1em auto auto
  gap: 0.5em
  margin-block: 1rem
  cursor: pointer
  line-height: 1
  sup
    display: contents
    font-style: italic
    // display: inline

input[type="checkbox"]
  appearance: none
  /* For iOS < 15 */
  background-color: var(--form-background)
  /* Not removed via appearance */
  margin: 0

  font: inherit
  color: currentColor
  width: 1.15em
  height: 1.15em
  border: 0.15em solid currentColor
  border-radius: 0.15em
  transform: translateY(-0.075em)

  display: grid
  place-content: center
  cursor: pointer

input[type="checkbox"]::before
  content: ""
  width: 0.65em
  height: 0.65em
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)
  transform: scale(0)
  transform-origin: bottom left
  transition: 120ms transform ease-in-out
  box-shadow: inset 1em 1em var(--form-control-color)
  /* Windows High Contrast Mode */
  background-color: CanvasText

input[type="checkbox"]:checked::before
  transform: scale(1)

// input[type="checkbox"]:focus
//   outline: max(2px, 0.15em) solid currentColor
//   outline-offset: max(2px, 0.15em)

input[type="checkbox"]:disabled
  --form-control-color: var(--form-control-disabled)

  color: var(--form-control-disabled)
  cursor: not-allowed
.clear
  text-align: left
  margin-top: 2rem
  font-size: smaller

.not-found
  text-align: center
.linked
  text-decoration: underline
  font-style: italic

.flez-home
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: stretch
  max-width: 1400px
  margin: auto
  h2
    width: 100%
    font-size: 3rem
  .col
    display: flex
    flex-direction: column
    margin-block: 1rem
    padding: 0 2rem
    border-left: 0.6rem solid #ebedec
    @media screen and ( max-width: 768px )
      border: 0

    &:first-of-type
      border: 0
    h3
      text-transform: uppercase
      text-align: center
      padding: 0 2rem 2rem
      font-weight: 500
    article
      overflow: hidden
    a[href=""]
      display: block
      opacity: 0.7
      pointer-events: none
      position: relative
      &::before
        position: absolute
        display: block
        z-index: 100
        color: #0c96db
        content: "coming soon"
        font-size: 4rem
        font-weight: 900
        font-family: monospace
        transform: translate(10rem, 7rem) rotate(-45deg)
        left: 0
        right: 0
        opacity: 0.4
        height: 100%
        width: 100%

article
  picture.full
    margin: auto
    justify-content: center
    img
      max-width: 90vw
      margin-right: auto
      margin-left: auto

.flez-home .three
  display: flex
  flex-wrap: wrap
  width: 100%
  justify-content: center
  figure.full
    width: 100%
    max-width: 300px
    max-height: unset
    justify-content: center
    filter: none
    margin: 1rem
    img
      width: 100%
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.45))
    figcaption
      font-style: normal
      text-align: center
      padding: 6px 0
      font-size: 1.6rem
      font-weight: bold
      time
        display: block
        width: 100%
        font-weight: 400
        text-shadow: none
        padding: 6px
        font-size: 1.3rem
  &.contacts
    figure.full
      max-height: 370px
      display: flex
      img
        object-fit: cover
